import { Card, Pagination, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./MyProfile.css";
import axios from "axios";
import { BASE_API_CONFIG } from "../../util/Api";
import { useSelector } from "react-redux";
import { formatDate } from "../../util/helper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile/myprofile";

const MyActivity = ({pointsData}) => {

  const { isMobile } = useGetBreakpoints();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return pointsData.slice(startIndex, endIndex);
  }, [pointsData, currentPage, pageSize]);
  
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: 'date',
      render: (text) => formatDate(text),
    },
    {
      title: "Description",
      key: "transactionname",
      dataIndex:'transactionname',
    },
    {
      title: "Points",
      key: "pointsconsumed",
      render: (text, record) => (
        <div>
          <img
            src={
              record.transactiontype === "CR"
                ? "/assets/images/positiveIcon.png"
                : "/assets/images/negativeIcon.png"
            }
            alt={
              record.pointsconsumed > 0
                ? "Positive points icon"
                : "Negative points icon"
            }
            style={{ marginRight: 8 }} // Optional: for spacing the icon from the number
          />
          <span
          style={{
            fontSize:"16px",
            fontWeight:"bold",
            fontFamily: "Inter", 
          }}>
          {record.transactiontype === "CR" ? "+" : "-"}
          {record.pointsconsumed} Points
          </span>
         
        </div>
      ),
    },
  ];

  const renderMobileView = useCallback(
    () =>
      paginatedData.map((point) => (
        <ItemDetailMobile
          key={point.key}
          postedDate={formatDate(point.date)}
          status={point.transactiontype}
          StatusComponent={({ status }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "18px" }}>
                <img
                  src={
                    status === "CR"
                      ? "/assets/images/positiveIcon.png"
                      : "/assets/images/negativeIcon.png"
                  }
                  alt={status === "CR" ? "Positive points icon" : "Negative points icon"}
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {point.transactionname}
                </div>
                <div style={{ fontSize: "14px", color: "#666" }}>
                  {`${point.transactiontype === "CR" ? "+" : "-"}${point.pointsconsumed} Points`}
                </div>
                <div style={{ fontSize: "12px", color: "#999" }}>
                  {`Transaction Type: ${point.transactiontype}`}
                </div>
              </div>
            </div>
          )}
        />
      )),
    [paginatedData]
  );
  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1);
    }
  };

  return (
    <Card style={{ borderRadius: "5px" }}>
      <div className="myexim-table-myactivity-title">Points History</div>
      {isMobile ? (
        <>
          <div className="mobile-payment-list" >{renderMobileView()}</div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={pointsData.length}
            onChange={handlePageChange}
            showSizeChanger={true} 
            showQuickJumper={false}
            simple
            style={{ marginTop: 16, textAlign: "center" }}
          />
        </>
      ) : (
      <Table
        rowKey={"key"}
        className="gx-table-responsive rfq-table myexim-table"
        columns={columns}
        dataSource={pointsData}
        pagination={{
          position: ["bottomRight"],
          pageSize: 5,
        }}
      />
      )}
    </Card>
  );
};

export default MyActivity;
