import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Card, Table, Dropdown, Grid } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import EnquiryStatusTags from "./EnquiriesStatus";
import { capitalize } from "lodash";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, isLessThan24Hrs } from "util/helper";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import { EnquiryStatus } from "util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance } from "util/Api";
import { BASE_API_CONFIG } from "./../../util/Api";
import { getBusinessBasedEnquiryTableColumns } from "./utils";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";
import {
  isManufacturer,
  isExporter,
  isCommissionAgent,
} from "../../util/helper";
import AppNotificationContainer from "../../components/AppNotificationContainer";
import { getProductsAction } from "../../appRedux/actions/myProductsAction";
import { useLocation } from "react-router-dom";
import { BUSINESS_TYPE, LEAD_DIFFER_DAYS, LEAD_TITLE } from "../../util/Constant";
import { string } from "prop-types";


const getStatus = (data) => {
  return (
    data?.readReciept[0]?.status ??
    (isLessThan24Hrs(data?.createdAt)
      ? EnquiryStatus.NEW_ENQUIRY
      : EnquiryStatus.AWAITING_FOR_QUOTATION)
  );
};

const lessThenSevenDays =(dateString) =>{
  const today =new Date();
  const date = new Date(dateString);
  const differInDays = Math.floor((today-date)/(1000*60*60*24));
  return differInDays <= LEAD_DIFFER_DAYS.DAYS;
}

const EnquiriesList = ({ fromDashboard }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  const currentPage = 1;
  const dataSize = fromDashboard ? 5 : 10;
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState();

  const authUser = useSelector(({ auth }) => auth.userData);
  const authUserId = useSelector(({ auth }) => auth?.userData?.businessTypeId);
  const productList = useSelector((state) => state.myProducts.productsList);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role") || "general";
  const tableTitle = useMemo(() => {
    if (role === BUSINESS_TYPE.BUYER) {
      return LEAD_TITLE.BUYER_LEADS;
    } else if (role === BUSINESS_TYPE.EXPORTER) {
      return LEAD_TITLE.EXPORTER_LEADS;
    } else {
      return LEAD_TITLE.LEADS;
    }
  }, [role]);
  useEffect(() => {
    dispatch(getProductsAction());
  }, [dispatch]);

  const userRestriction =
    !isArrayNonEmpty(productList?.rows) &&
    (isManufacturer(authUser) ||
      isExporter(authUser) ||
      isCommissionAgent(authUser));

  const navigateToDetails = useCallback(
    (id, qid) => {
      if (isValidString(id) && isValidString(qid)) {
        history.push(`enquiries/details?id=${id}&qid=${qid || ""}&role=${role}`);
      } else if (isValidString(id)) {
        history.push(`enquiries/details?id=${id}&role=${role}`);
      }
    },
    [history]
  );

  const handleNavigation = useCallback(
    (record) => {
      navigateToDetails(record?.id, record?.readReciept[0]?.quotationId);
    },
    [navigateToDetails]
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: "Posted Date",
        key: "createdAt",
        render: (record) => <div>{formatDate(record?.createdAt)}</div>,
      },
      {
        title: "Product Name",
        key: "productName",
        sorter: true,
        render: (record) => <div>{record?.productName}</div>,
      },
      {
        title: "Quantity",
        key: "quantityRequired",
        render: (record) => (
          <div 
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {record?.qty ? (
              <>
                {record?.qty} {record?.units}
              </>
            ) : (
              <>
                {record?.noOfContainers}{" "}
                {record?.noOfContainers === 1 ? "Container" : "Containers"}
              </>
            )}
          </div>
        ),
        width: 200,
      },
      {
        title: "Status",
        key: "Status",
        sorter: true,
        render: (record) => {
          let status = getStatus(record);
          return <EnquiryStatusTags status={status} />;
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => (
          <div
            className="myexim-action-icon"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown
              menu={{
                items: [
                  {
                    label: "View Details",
                    key: "1",
                    icon: <EyeOutlined />,
                    onClick: () => {
                      handleNavigation(record);
                    },
                  },
                ],
              }}
              trigger={["click"]}
            >
              <MoreOutlined />
            </Dropdown>
          </div>
        ),
      },
    ];
    const newColumns = [...baseColumns];
    if (
      authUserId !== 3 &&
      authUserId !== 4 &&
      role !== BUSINESS_TYPE.EXPORTER
    ) {
      newColumns.splice(4, 0, {
        title: "Destination Country",
        key: "destinationCountry",
        render: (record) => <div>{record?.destinationCountry}</div>,
      });
      newColumns.splice(5, 0, {
        title: "Destination Port",
        key: "destinationPort",
        render: (record) => <div>{record?.destinationPort}</div>,
      });
    }
    return newColumns;
  }, [authUserId,role,handleNavigation]);

  const fetchEnquiries = useCallback(
    async (page, limit) => {
      try {
        dispatch(fetchStart());
        const response = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/enquiries/businessType?page=${page}&limit=${limit}&role=${role}`
        );
        setLoading(false);
        setTableData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      } finally {
        dispatch(fetchSuccess());
      }
    },
    [dispatch, role]
  );

  const onShowSizeChange = useCallback(
    (current, pageSize) => {
      dispatch(fetchEnquiries(current, pageSize));
    },
    [dispatch, fetchEnquiries]
  );

  const renderContent = useCallback(
    (data, count, activity, showEmptyNoteBasedOnRestriction) => {
      return (
        <Card
          className={isMobile ? "mobile-view" : ""}
          title={<div className="myexim-table-title">{tableTitle}</div>}
        >
          {isArrayNonEmpty(data) ? (
            isMobile ? (
              data.map((record) => {
                return (
                  <ItemDetailMobile
                    postedDate={formatDate(record.createdAt)}
                    primaryRow={{
                      text: record.productName,
                      subText: `${record.qty} ${record.units}`,
                    }}
                    secondaryRow={{
                      text: record.user.quatationsleft,
                      subText: `${capitalize(record.destinationPort)},${
                        record.destinationCountry
                      }`,
                    }}
                    status={getStatus(record)}
                    StatusComponent={EnquiryStatusTags}
                    handleNavigation={() => {
                      handleNavigation(record);
                    }}
                  />
                );
              })
            ) : (
              <Table
                rowKey={"id"}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      // Add specific conditions here for menu items
                      if (
                        !e.target.classList.contains(
                          "ant-dropdown-menu-title-content"
                        )
                      ) {
                        handleNavigation(record);
                      }
                    },
                  };
                }}
                className="gx-table-responsive rfq-table myexim-table"
                columns={columns.filter((obj) =>
                  getBusinessBasedEnquiryTableColumns(
                    authUser?.businessTypes?.code
                  )?.includes(obj.key)
                )}
                dataSource={data}
                pagination={
                  !fromDashboard && {
                    position: ["bottomRight"],
                    pageSize: 10,
                    showSizeChanger: true,
                    total: count,
                    onChange: onShowSizeChange,
                  }
                }
              />
            )
          ) : (
            !activity && (
              <ListEmptyComponent
                message={
                  showEmptyNoteBasedOnRestriction
                    ? "Create Your Products to get the Enquiries for your Listed Products"
                    : "At the moment, No Enquiries are Available. we will notify you when New Enquiries are received."
                }
                buttonText={
                  showEmptyNoteBasedOnRestriction
                    ? " + Create New Products"
                    : ""
                }
                onButtonClick={() => history.push("my-products/edit")}
              />
            )
          )}
        </Card>
      );
    },
    [
      authUser?.businessTypes?.code,
      columns,
      fromDashboard,
      handleNavigation,
      isMobile,
      onShowSizeChange,
    ]
  );

  useEffect(() => {
    setLoading(true);
    fetchEnquiries(currentPage, dataSize);
  }, [dataSize, fetchEnquiries]);

  return !loading ? (
    renderContent(tableData?.rows, tableData?.count, loading, userRestriction)
  ) : (
    <AppNotificationContainer loading={loading} />
  );
};

export default EnquiriesList;
