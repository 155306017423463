import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Modal, Typography } from "antd";
import {
  createRazorpayOrder,
  initiateRazorpayCheckout,
  loadRazorpay,
  verifyRazorpayPayment,
} from "../../util/RazorpayHelper";
import { CURRENCY_CODE_INR, RUPEE_PER_POINT } from "../../util/Constant";
import { useSelector } from "react-redux";


const PurchasePointModal = ({
  isPurchasePointModalOpen,
  onClose,
  openSuccessModal,
  openFailedModal,
  openLoadingModal,
  closeLoadingModal,
  refreshBalance,
}) => {
  const authUser = useSelector(({ auth }) => auth.userData);
  const [count, setCount] = useState(1);
  const rupeePerPoint = RUPEE_PER_POINT;
  const payableAmount = count * rupeePerPoint;
  const [calculatedAmount, setCalculatedAmount] = useState(payableAmount);
  const { Text } = Typography;

  useEffect(() => {
    loadRazorpay();
  }, []);

  useEffect(() => {
    setCalculatedAmount(count * rupeePerPoint);
  }, [count, rupeePerPoint]);

  const handleIncrement = () => {
    setCount((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setCount((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setCount(value);
    }
  };

  const handleClosePurchasePointModal = () => {
    if (onClose) {
      onClose();
    }
  };


  const pointsPurchaseSuccessHandler = useCallback(
    async (response) => {
      console.log("@@ purchase succesful", response);
      try {
        const uuid = authUser.uuid;
        openLoadingModal();
        const paymentVerification = await verifyRazorpayPayment(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          uuid,
          response.razorpay_signature,
          count,
        );
        if (!paymentVerification.isValid) {
          throw new Error("Payment verification failed!");
        }

        refreshBalance();
        closeLoadingModal();
        openSuccessModal();
      } catch (error) {
        console.log("## payment verification failed", error);
        openFailedModal();
        closeLoadingModal();
      }
    },
    [
      authUser.uuid,
      openLoadingModal,
      count,
      refreshBalance,
      closeLoadingModal,
      openSuccessModal,
      openFailedModal,
    ]
  );


  const pointsPurchaseErrorHandler = useCallback(
    async (response) => {

      let errorMessage,errorReason, paymentId, orderId,uuid;
  
      try {
        openLoadingModal();

        errorReason = response.error?.reason;
        paymentId = response.error?.metadata?.payment_id;
        orderId = response.error?.metadata?.order_id;
        uuid = authUser.uuid;
      } catch (error) {
        console.error("Error extracting payment failure details:", error);
      }
  
      try {
        const paymentVerification = await verifyRazorpayPayment(
          orderId,
          paymentId,
          uuid,
          errorReason,
          
        
        );
        closeLoadingModal();

      } catch (backendError) {
        console.error("Failed to report payment failure to backend:", backendError);
      }
  
      openFailedModal({
        title: "Payment Failed",
        message: errorMessage,
        details: `Reason: ${errorReason}. Please try again or contact support.`,
      });
    },
    [openFailedModal, authUser.uuid, refreshBalance]
  );

  const initiateRazorpayPaymentFlow = useCallback(async () => {
    try {
      handleClosePurchasePointModal();
      openLoadingModal();
      const amountInSmallestUnit = calculatedAmount * 100;
      const order = await createRazorpayOrder(
        amountInSmallestUnit,
        CURRENCY_CODE_INR
      );
      if (onClose) {
        onClose();
      }
      closeLoadingModal();
      initiateRazorpayCheckout(
        order,
        {
          contact: authUser.mobile,
        },
        pointsPurchaseSuccessHandler,
        pointsPurchaseErrorHandler
      );
    } catch (error) {
      console.log("razorpay payment error", error);
      openFailedModal();
    }
  }, [handleClosePurchasePointModal, openLoadingModal, calculatedAmount, onClose, closeLoadingModal, authUser.mobile, pointsPurchaseSuccessHandler, pointsPurchaseErrorHandler, openFailedModal]);

  return (
    <div>
      <Modal
        open={isPurchasePointModalOpen}
        onCancel={handleClosePurchasePointModal}
        footer={null}
        closable={false}
        width="375px"
        maskClosable={false}
        className="purchase-modal"
      >
        <div className="modal-header">
          <img
            onClick={handleClosePurchasePointModal}
            src="/assets/images/back.png"
            alt="back"
            className="back-icon-custom"
          />
          <Text className="modal-title">Purchase Points</Text>
        </div>

        <div className="modal-content">
          <div>
            <Text className="description">
              Enter the amount of points to purchase
            </Text>
          </div>
          <div className="counter-container">
            <div className="counter-wrapper">
              <button onClick={handleDecrement} className="counter-button">
                -
              </button>
              <input
                type="text"
                className="counter-display"
                value={count}
                onChange={handleInputChange}
              />
              <button onClick={handleIncrement} className="counter-button">
                +
              </button>
            </div>
          </div>

          <div>
            <Text className="price-details">
              {count} {count === 1 ? "Point" : "Points"} = INR{" "}
              {calculatedAmount}
            </Text>
          </div>
        </div>

        <Card className="note-card">
          <div className="note-header">
            <img
              src="/assets/images/infoIcon.png"
              alt="icon"
              className="info-icon"
            />
            <Text className="note-title">Note</Text>
          </div>
          <Text className="note-content">
            • 1 Point equals 100 INR (Indian Rupee)
          </Text>
        </Card>

        <div className="button-container">
          <Button
            onClick={initiateRazorpayPaymentFlow}
            className="primary-button"
            size="small"
            style={{
              width: "100%",
              fontSize: "16px",
              textAlign:"center",
              marginBottom:"20px",
              height: "50px",
              lineHeight: "20px",
            }}
          >
            Proceed to Payment
          </Button>
          <Button
            onClick={handleClosePurchasePointModal}
            size="small"
            className="secondary-button"
            style={{
              width: "100%",
              fontSize: "16px",
              textAlign:"center",
              marginBottom:"20px",
              height: "45px",
              lineHeight: "20px",
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PurchasePointModal;
