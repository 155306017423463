import { axiosInstance, BASE_API_CONFIG } from "./Api";
import { lazyLoadScript } from "./helper";

export const RAZORPAY_CHECKOUR_URL =
  "https://checkout.razorpay.com/v1/checkout.js";

const razorpayConfigDefaults = {
  key: process.env.RZP_KEY_ID,
  name: "MY EXIM BUSINESS",
  image: "https://myeximbusiness.com/favicon.ico",
};

export const loadRazorpay = async () => {
  const isScriptLoaded = await lazyLoadScript(RAZORPAY_CHECKOUR_URL);

  if (!isScriptLoaded) {
    console.log("## Failed to load Razorpay!");
  }
};

export const createRazorpayOrder = async (amount, currencyCode ,uuid) => {
  return axiosInstance
    .post(`${BASE_API_CONFIG.baseURL}/integrations/create-order`, {
      amount,
      currency: currencyCode,
      uuid,
    }
  )
    .then((response) => response.data);
};

export const initiateRazorpayCheckout = (
  order,
  userData,
  successHandler,
  errorHandler
) => {
  const checkoutOptions = {
    ...razorpayConfigDefaults,
    prefill: userData,
    description: "transaction description",
    amount: order.amount,
    currency: order.currency,
    order_id: order.id,
    handler: successHandler,
    
  };
  console.log("checkoutOptions", checkoutOptions);
  const razorPayInstance = new window.Razorpay(checkoutOptions);
  razorPayInstance.on("payment.failed", errorHandler);
  razorPayInstance.open();
};

export const verifyRazorpayPayment = async (
  orderId,
  paymentId,
  uuid,
  signature,
  pointsToAdd,
  errorReason, 
) => {
  return axiosInstance
    .post(`${BASE_API_CONFIG.baseURL}/integrations/verify-payment`, {
      orderId,
      paymentId,
      uuid,
      signature,
      pointsToAdd,
      errorReason,
    })
    
    .then((response) => response.data)
    .catch((error) => error.response);

    
};
